import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { routes } from 'src/routes';
import { MainLayout } from './components/main-layout';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { blue, pink } from '@mui/material/colors';

import { LoginForm } from 'src/components/login-form';

import { useGetUserMe } from './entities/user';

const darkTheme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
    mode: 'dark',
    background: {
      default: '#303030',
      paper: '#424242',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
  },
});

const ProtectedRoute = ({ user, children, loading, permittedRoles }: any) => {
  if (loading) {
    return <>Загрузка...</>;
  }

  if (
    permittedRoles.some((premittedRole: string) =>
      user?.roles?.includes(premittedRole),
    )
  )
    return children;

  return <Navigate to="/login" replace />;
};

function App() {
  const meRequest = useGetUserMe();
  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <MainLayout>
          <CssBaseline />
          <Routes>
            <Route
              path="/login"
              element={
                <LoginForm
                // redirect={
                //   // @ts-ignore
                //   props?.location?.state?.from
                // }
                />
              }
            />
            {routes.map(({ component, ...route }) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    user={meRequest.data}
                    loading={meRequest.isLoading}
                    permittedRoles={route.roles}
                  >
                    <>{component}</>
                  </ProtectedRoute>
                }
              />
              // <PrivateRoute
              //   key={route.path}
              //   exact
              //   path={route.path}
              //   permittedRoles={route.roles}
              //   component={(props: any) => <MainLayout>{component}</MainLayout>}
              // />
            ))}
          </Routes>
        </MainLayout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
