import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useRouter } from 'src/hooks/useRouter';
import { routesMap } from 'src/routes';

//entities
import { useGetReportsList } from 'src/entities/report';
import { useGetUsers } from 'src/entities/user';

//shared
import { DateControl } from 'src/shared/ui/entity-control';
import { ReportCofig, request } from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';
import { downloadXlsx } from 'src/components/common/DownloadXlsx';
import { removeEmptyProps } from 'src/helpers/removeEmtyProperties';

export const Report = ({
  report,
  params,
}: {
  report: ReportCofig;
  params: any;
}) => {
  const [loading, setLoading] = useState(false);

  const getReport = () => {
    setLoading(true);
    request
      .get(
        `${apiRoutesMap.reportsList.getRoute()}/${
          report.link
        }?${new URLSearchParams(removeEmptyProps(params))}`,
      )
      .then((res: any) => {
        setLoading(false);
        downloadXlsx([
          {
            sheet: 'sheet',
            columns: Object.keys(res?.[0])?.map(item => ({
              label: item,
              value: item,
            })),
            content: res,
          },
        ]);
      });
  };

  return (
    <Button onClick={getReport} disabled={loading}>
      {report.description}
    </Button>
  );
};

interface Props {}

export const ReportsPage: React.FunctionComponent<Props> = ({}) => {
  const { query, ...router } = useRouter();

  const reportsRequest = useGetReportsList();

  const usersCountRequest = useGetUsers({
    variables: { from: query.from, to: query.to, limit: 1 },
  });

  const managersCountRequest = useGetUsers({
    variables: { from: query.from, to: query.to, type: 'manager', limit: 1 },
  });

  const params = { from: query.from, to: query.to };

  return (
    <Grid container spacing={4}>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <DateControl
          type="date"
          format="yyyy-MM-dd"
          onChange={value =>
            router.push(
              `${routesMap.reports.getRoute()}?${new URLSearchParams({
                ...query,
                from: value,
              })}`,
            )
          }
          value={query.from}
          editable
          label="От"
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 2 }}>
        <DateControl
          type="date"
          format="yyyy-MM-dd"
          onChange={value =>
            router.push(
              `${routesMap.reports.getRoute()}?${new URLSearchParams({
                ...query,
                to: value,
              })}`,
            )
          }
          value={query.to}
          editable
          label="До"
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <div>Кол-во пользователей: {usersCountRequest.data?.totalCount}</div>
        <div>Кол-во менеджеров: {managersCountRequest.data?.totalCount}</div>
      </Grid>
      <Grid size={{ xs: 12 }}>
        {reportsRequest.data?.data?.map(report => (
          <Box key={report.link} mt={2}>
            <Report report={report} params={params} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};
