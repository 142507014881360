import React, { useEffect, useState } from 'react';
import { Box, Paper, TextField, } from '@mui/material';

import {
  changeSmatTaskProducts,
  useGetSmartTask,
} from 'src/entities/smart-task';
import { useDebounce } from 'src/hooks/useDebounce';
import { Table } from 'src/components/common/Table';
import { Product } from 'src/types/api';
import { ProductPreview } from 'src/components/ProductPreview';
import { StockItemsDialog } from 'src/components/StockItemsDialog';
import { StockItemPrice } from 'src/components/StockItemPrice';
import { requestCodesByBarcodes } from 'src/entities/product/api';

interface Props {}

export const SmartTaskPage: React.FunctionComponent<Props> = ({}) => {

  const [barcodes, setBarcodes] = useState('');

  const [barcodesProducts, setBarcodesProducts] = useState<null | {
    [key: number]: Product;
  }>(null);

  const [productsCodes, setProductsCodes] = useState('');

  const smartTaskRequest = useGetSmartTask();

  const taskCodes = smartTaskRequest?.data?.data?.products
    ?.map(product => product.code)
    ?.sort()
    ?.join(' ');

  useEffect(() => {
    if (taskCodes) {
      setProductsCodes(taskCodes);
    }
  }, [smartTaskRequest.data]);

  const debouncedProductsCodes = useDebounce<string>(productsCodes, 800);

  useEffect(() => {
    const codes = debouncedProductsCodes
      .split(/[ ,\n]+/)
      ?.filter(item => item)
      ?.sort();
    if (taskCodes !== debouncedProductsCodes) {
      changeSmatTaskProducts(codes).then(() => {
        smartTaskRequest.mutate();
      });
    }
  }, [debouncedProductsCodes]);

  const debouncedBarcodes = useDebounce<string>(barcodes, 800);

  const barcodesList = debouncedBarcodes
    .split(/[ ,\n]+/)
    ?.filter(item => item)
    ?.sort();

  useEffect(() => {
    if (barcodes) {
      requestCodesByBarcodes(barcodesList).then(res =>
        setBarcodesProducts(res?.data),
      );
    }
  }, [debouncedBarcodes]);

  const products = smartTaskRequest?.data?.data?.products;

  const found = barcodesProducts && Object.values(barcodesProducts)
    ?.filter(item => item)
    ?.map(product => product?.code)

  const missing =
    barcodesProducts &&
    Object.entries(barcodesProducts)
      ?.filter(item => !item[1])
      ?.map(item => item[0])

  return (
    <div>
      <Box>
        <TextField
          multiline
          label="Поиск кодов по штрих-кодам"
          fullWidth
          value={barcodes}
          onChange={e => setBarcodes(e?.target?.value || '')}
        />
      </Box>
      {barcodesProducts && (
        <Box mt={2}>
          <Box>
            Коды товаров ({found?.length}):{' '}
            {found?.join(', ')}
          </Box>
          {Boolean(missing?.length) && <Box>Не удалось найти ({missing?.length}): {missing?.join(', ')}</Box>}
        </Box>
      )}

      <Box mt={2}>
        <TextField
          multiline
          label="Изменить коды"
          fullWidth
          value={productsCodes}
          onChange={e => setProductsCodes(e?.target?.value || '')}
        />
      </Box>
      {Boolean(products?.length) && (
        <Box mt={2}>
          <Paper>
            <Table
              dense
              data={products || []}
              headers={[
                {
                  title: 'Товар',
                  extract: (item: Product) => <ProductPreview product={item} />,
                },
                {
                  title: 'Цена',
                  align: 'right',
                  extract: (item: Product) => {
                    const minPriceItem = item?.stockItems?.sort((a, b) =>
                      a?.offerPrice &&
                      b?.offerPrice &&
                      a?.offerPrice > b?.offerPrice
                        ? -1
                        : 1,
                    )?.[0];

                    return (
                      <StockItemsDialog items={item?.stockItems}>
                        <StockItemPrice offerPrice={minPriceItem?.offerPrice} />
                      </StockItemsDialog>
                    );
                  },
                },
              ]}
              loading={smartTaskRequest?.isLoading}
              onFetch={smartTaskRequest?.mutate}
              totalCount={smartTaskRequest?.data?.totalCount}
              limit={smartTaskRequest?.data?.totalCount}
              offset={0}
            />
          </Paper>
        </Box>
      )}
    </div>
  );
};
