import React, { useState } from 'react';

import { Autocomplete } from './common/Autocomplete';
import { orderBy } from 'lodash';
import { Contractor } from 'src/shared/api';
import { useGetContractors } from 'src/entities/contractor';
import { useDebounce } from 'src/hooks/useDebounce';

interface Props {
  onSelect?: (contractor: Contractor | null) => any;
}

export const ContractorSelect: React.FunctionComponent<Props> = ({
  onSelect,
}) => {
  const [query, setQuert] = useState('');

  const debouncedQuery = useDebounce<string>(query, 500);

  const contractors = useGetContractors({
    variables: { query: debouncedQuery },
    skip: !debouncedQuery,
  });

  return (
    <div>
      <Autocomplete
        label="Контрагент"
        options={orderBy(contractors.data?.data || [], ['name'])}
        loading={contractors.isLoading}
        onChange={(e, value: Contractor) => onSelect && onSelect(value)}
        onChangeTextField={e => setQuert(e.target.value)}
        getOptionLabel={option => {
          return `${option.name} ${option.inn}`;
        }}
      />
    </div>
  );
};
