import React from 'react';
import { Link, ListItem, ListItemText, Theme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { format } from 'date-fns';

import { routesMap } from 'src/routes';

//entities
import { CallTaskCreate, useGetCallTasks } from 'src/entities/call';

//shared
import { EntityList } from 'src/shared/ui/entity-view/EntityList';
import { CallTask } from 'src/shared/api';

interface Props {}

const config = {
  renderItem: (props: {
    item: { data: CallTask; key: number; href: string };
    index: number;
  }) => (
    <ListItem key={props?.item?.data?.id} divider>
      <ListItemText
        primary={
          <Link href={props.item.href}>
            {props.item.data.name || props.item.data.phonenumber}
          </Link>
        }
        secondary={format(
          new Date(props.item.data.createdAt),
          'yyyy-MM-dd HH:mm:ss',
        )}
      />
    </ListItem>
  ),
};

export const CallTasksPage: React.FunctionComponent<Props> = ({}) => {
  const callTasksRequest = useGetCallTasks();

  return (
    <Grid container>
      <Grid size={{ xs: 12, md: 6 }}>
        <CallTaskCreate onCreate={callTasksRequest.mutate} />
        <EntityList
          items={callTasksRequest?.data?.data?.map(item => ({
            data: item,
            key: item.id,
            href: routesMap.callTask.getRoute(item.id),
          }))}
          config={config}
        />
      </Grid>
    </Grid>
  );
};
