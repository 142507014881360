import React from 'react';
import { Article, ArticlePropName } from 'src/shared/api';
import { ListItemText, Link, Checkbox, ListItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { format } from 'date-fns';

import { routesMap } from 'src/routes';

//entities
import { createArticle, useGetArticles } from 'src/entities/article';

// shared
import { EntityList } from 'src/shared/ui/entity-view/EntityList';
import { EntityCreate } from 'src/shared/ui/entity-create';

interface Props {}

const config = {
  renderItem: (props: {
    item: { data: Article; key: number; href: string };
    index: number;
  }) => (
    <ListItem
      key={props?.item?.data?.id}
      divider
      secondaryAction={
        <Checkbox checked={props.item.data.published} disabled />
      }
    >
      <ListItemText
        primary={<Link href={props.item.href}>{props.item.data.title}</Link>}
        secondary={format(
          new Date(props.item.data.startTime),
          'yyyy-MM-dd HH:mm:ss',
        )}
      />
    </ListItem>
  ),
};

export const ArticlesPage: React.FunctionComponent<Props> = ({}) => {
  const articlesRequest = useGetArticles();

  const onCreate = (data: any) =>
    createArticle(data).then(articlesRequest.mutate);

  return (
    <Grid container>
      <Grid size={{ xs: 12, md: 6 }}>
        <EntityCreate
          item={{
            title: '',
            published: false,
            body: '',
            startTime: format(new Date(), 'yyyy-MM-dd hh:MM:ss'),
          }}
          config={{
            onChange: onCreate,
            skipUnconfigured: true,
            props: {
              title: {
                editable: true,
                label: ArticlePropName.title,
              },
            },
          }}
        />
        <EntityList
          items={articlesRequest?.data?.data?.map(item => ({
            data: item,
            key: item.id,
            href: routesMap.article.getRoute(item.id),
          }))}
          config={config}
        />
      </Grid>
    </Grid>
  );
};
