import React from 'react';
import { Box } from '@mui/material';
import { green, red } from '@mui/material/colors';

import { formatPrice } from 'src/helpers/formatting';
interface Props {
  offerPrice?: number | null;
  singlePrice?: number | null;
  diff?: number | null;
}

export const StockItemPrice: React.FunctionComponent<Props> = ({
  offerPrice,
  singlePrice,
  diff,
}) => {
  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      <div>
        <b>{offerPrice && formatPrice(offerPrice / 100)}&nbsp;₽</b>
      </div>
      {Boolean(singlePrice) && (
        <Box
          sx={theme => ({
            fontSize: theme.typography.pxToRem(11),
            opacity: 0.5,
          })}
        >
          {singlePrice && formatPrice(singlePrice / 100)}
          &nbsp;₽&nbsp;за&nbsp;шт.
        </Box>
      )}

      {Boolean(diff) && (
        <div
          style={{ color: diff && diff > 0 ? red[500] : green[500] }}
          title="Отличие от центы заказа клиента"
        >
          <b>
            {diff && diff > 0 && '+'}
            {diff && formatPrice(diff / 100)}&nbsp;₽
          </b>
        </div>
      )}
    </Box>
  );
};
