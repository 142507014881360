import React, { useState } from 'react';
import { Box, Paper, TextField } from '@mui/material';
import { Users } from 'src/components//users';
import { useRouter } from 'src/hooks/useRouter';
import { routesMap } from 'src/routes';
import { apiService } from 'src/services/apiService';
import useSWR, { mutate } from 'swr';
import { UserCreate } from '../UserCreate';
import { useDebounce } from 'src/hooks/useDebounce';

interface Props {}
export const UsersPage: React.FunctionComponent<Props> = ({}) => {

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { push, query } = useRouter();

  const requestQuery = {
    limit: query.limit || 12,
    offset: query.offset || 0,
    query: debouncedSearchQuery as string,
  };

  const swrKey = [
    apiService.v1Api.usersList.name,
    requestQuery.limit,
    requestQuery.offset,
    requestQuery.query,
  ];

  const { data, isValidating } = useSWR(
    requestQuery.query ? swrKey : null,
    () => apiService.v1Api.usersList(requestQuery).then(res => res.data),
    { revalidateOnFocus: false },
  );

  const handleOnChange = (params: { page: number; limit: number }) => {
    const nextRequestQuery = {
      ...requestQuery,
      offset: params.page * params.limit,
      limit: params.limit,
    };

    push(routesMap.users.getRoute(), nextRequestQuery);
  };

  return (
    <div>
      <Paper>
        <Box p={2} pb={0}>
          <TextField
            label="Поиск"
            variant="outlined"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </Box>
      </Paper>
      <Users
        actions={[() => <UserCreate onCreate={() => mutate(swrKey)} />]}
        data={data?.data}
        totalCount={data?.totalCount}
        onFetch={() => mutate(swrKey)}
        onChange={handleOnChange}
        loading={isValidating}
        limit={parseInt(requestQuery.limit, 10)}
        offset={parseInt(requestQuery.offset, 10)}
      />
    </div>
  );
};
