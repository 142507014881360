import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { Users } from 'src/components/users';
import { Outlets } from 'src/components/Outlets';
import { Add, Close } from '@mui/icons-material';
import { UserSelect } from '../user-select';
import { WithLoading } from 'src/components/common/WithLoading';
import {
  useGetContractor,
  apiService,
  useGetOrders,
} from 'src/services/apiService';
import { useParams } from 'react-router-dom';
// import { mutate } from 'swr';
import { EntityTitle } from '../EntityTitle';
import { Orders } from '../orders';
import { Contractor, User, UserRole } from 'src/shared/api';
import { PermissionWrapper } from 'src/features/permission-wrapper';
import { WithConfirm } from 'src/shared/ui';
import { deleteUserFromContractor } from 'src/entities/contractor';

interface Props {}

export const ContractorPage: React.FunctionComponent<Props> = ({}) => {
  const [dialog, setDialog] = useState<any>(false);
  const [user, setUser] = useState<User | null>(null);

  const { contractorId } = useParams<{ contractorId: string }>();

  const {
    data: contractor,
    isLoading,
    mutate,
  } = useGetContractor({ contractorId });
  const ordersRequest = useGetOrders({
    variables: {
      outlets: contractor?.outlets?.map(item => item.id)?.join(','),
      state: 'processed',
    },
    skip: !contractor?.outlets?.length,
  });

  const handleAdd = () => {
    if (contractor?.id && user) {
      apiService.v1Api
        .contractorsUsersCreate(`${contractor.id}`, user)
        .then(res => {
          setDialog(false);

          mutate();

          return res.data;
        });
    }
  };

  const handleDelete = (userForDelete: User) => {
    if (contractor?.id && userForDelete?.id) {
      deleteUserFromContractor(contractor.id, userForDelete.id).then(() =>
        mutate(),
      );
    }
  };

  return (
    <div>
      <EntityTitle id={contractor?.id} title={contractor?.name} />
      <Box mt={4}>
        <Paper>
          <List>
            <ListItem>
              <ListItemText primary={contractor?.inn} secondary="ИНН" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={contractor?.vendorId}
                secondary="vendorId"
              />
            </ListItem>
          </List>
        </Paper>
      </Box>
      <Box mt={4}>
        <Users
          data={contractor?.users}
          totalCount={contractor?.users?.length}
          loading={isLoading}
          itemActions={user => (
            <WithConfirm
              onConfirm={() => handleDelete(user)}
              confirmTitle="Удалить"
            >
              <IconButton
                size="small"
                color="error"
                title="Удалить пользователя"
              >
                <Close />
              </IconButton>
            </WithConfirm>
          )}
          actions={[
            () => (
              <PermissionWrapper roles={[UserRole.ROLE_ADMIN]}>
                <>
                  <IconButton
                    color="primary"
                    title="Добавить пользователя"
                    onClick={() => setDialog(true)}
                  >
                    <Add />
                  </IconButton>
                  <Dialog
                    open={dialog}
                    onClose={() => setDialog(false)}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogContent>
                      <UserSelect user={user} onChange={setUser} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setDialog(false)}>Закрыть</Button>
                      <WithLoading loading={false}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!user}
                          onClick={handleAdd}
                        >
                          Добавить
                        </Button>
                      </WithLoading>
                    </DialogActions>
                  </Dialog>
                </>
              </PermissionWrapper>
            ),
          ]}
        />
      </Box>
      <Box mt={4}>
        <Outlets
          data={contractor?.outlets}
          totalCount={contractor?.outlets?.length}
          loading={isLoading}
        />
      </Box>
      <Box mt={4}>
        <Orders
          data={ordersRequest?.data?.data}
          totalCount={ordersRequest?.data?.totalCount}
          loading={ordersRequest.isLoading}
        />
      </Box>
    </div>
  );
};
