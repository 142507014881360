import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { BottomNavigation as BottomNavigationMui } from '@mui/material';

interface Props {}

export const BottomNavigation: React.FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, ...rest }) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        zIndex: 100,
        borderRadius: '8px',
        position: 'fixed',
        bottom: '8px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        border: '1px solid grey',
      }}
    >
      <BottomNavigationMui showLabels {...rest}>
        {children}
      </BottomNavigationMui>
    </Box>
  );
};
