import React from 'react';
import { Paper } from '@mui/material';
import { Table } from 'src/components/common/Table';
import { format } from 'date-fns';
import { Outlet } from 'src/types/api';

interface Props {
  loading: boolean;
  data?: Outlet[] | null;
  totalCount?: number | null;
  onFetch?: () => any;
  onClickRow?: (order: Outlet) => any;
  onChange?: (params: { page: number; limit: number }) => any;
}

export const Outlets: React.FunctionComponent<Props> = ({
  data,
  totalCount,
  loading,
  onChange,
  onFetch,
  onClickRow,
}) => {
  return (
    <div>
      <Paper>
        <Table
          title="Торговые точки"
          headers={[
            {
              title: 'id',
              component: 'th',
              scope: 'row',
              align: 'right',
              extract: (item: Outlet) => item.id,
            },
            {
              title: 'Дата создания',
              extract: (item: Outlet) =>
                item.createdAt &&
                format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
            },
            {
              title: 'Адрес',
              extract: (item: Outlet) => item?.address?.line,
            },
            {
              title: 'Vendor id',
              align: 'right',
              extract: (item: Outlet) => item.vendorId,
            },
          ]}
          data={data}
          totalCount={totalCount}
          onFetch={onFetch}
          onChange={onChange}
          loading={loading}
          onClickRow={onClickRow}
        />
      </Paper>
    </div>
  );
};
