import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { format } from 'date-fns';

import { WithLoading } from 'src/components/common/WithLoading';

//entities
import { createPromotion } from 'src/entities/promotion';

//shared
import { DateControl } from 'src/shared/ui/entity-control';

interface Props {
  afterCreate: () => any;
}

export const CreatePromotion: React.FunctionComponent<Props> = ({
  afterCreate,
}) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [start, setStartTime] = useState<any>(null);
  const [finish, setFinishTime] = useState<any>(null);

  const onOpen = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const reset = () => {
    setLoading(false);
    setTitle('');
    setStartTime(null);
    setFinishTime(null);
  };

  const handleCreate = () => {
    setLoading(true);
    createPromotion({
      title,
      start: format(new Date(start), 'yyyy-MM-dd'),
      finish: format(new Date(finish), 'yyyy-MM-dd'),
    }).then(() => {
      onClose();
      afterCreate();
    });
  };

  const disabled = !title || !start || !finish;

  return (
    <>
      <Fab color="primary" size="small" onClick={onOpen}>
        <Add />
      </Fab>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Создание акции</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Название"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <DateControl
              editable
              label="Дата начала"
    
              onChange={setStartTime}
            />
          </Box>
          <Box mt={2}>
            <DateControl
              editable
         
              label="Дата окончания"
              onChange={setFinishTime}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Отмена</Button>
          <WithLoading loading={loading}>
            <Button
              onClick={handleCreate}
              disabled={disabled}
              color="primary"
              variant="contained"
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </>
  );
};
