import React, { PropsWithChildren } from 'react';
import {  Box } from '@mui/material';
import { CircularProgress, Fade, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { red } from '@mui/material/colors';

interface Props {
  loading: boolean;
  onCancel?: any;
}

export const WithLoading: React.FunctionComponent<PropsWithChildren<Props>> = ({
  loading,
  onCancel,
  children,
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Fade in={!loading} timeout={{ enter: 300, exit: 800 }}>
        <div>{children}</div>
      </Fade>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
        <div>
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -12,
              marginLeft: -12,
            }}
          />
          {onCancel && (
            <Fade
              in={loading}
              style={{
                transitionDelay: '3000ms',
              }}
            >
              <IconButton
                onClick={onCancel}
                sx={{
                  color: red[500],
                  opacity: 0.5,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -21,
                  marginLeft: -21,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Close
                  sx={{
                    width: '18px',
                    height: '18px',
                  }}
                />
              </IconButton>
            </Fade>
          )}
        </div>
      </Fade>
    </Box>
  );
};
