import React from 'react';

import { UserSelect } from 'src/components/user-select';
import { createCallTask } from '../api';

//shared
import { CallTask, CallTaskPropName } from 'src/shared/api';
import { EntityCreate } from 'src/shared/ui/entity-create';

interface Props {
  onCreate: () => any;
  label?: string;
  state?: Partial<CallTask>;
  requiredProps?: string[];
}

export const CallTaskCreate: React.FunctionComponent<Props> = ({
  onCreate,
  label,
  state,
  requiredProps = [],
}) => {
  const handleCreate = (data: any) =>
    createCallTask({ ...data, executor: data.executor.id }).then(onCreate);

  return (
    <EntityCreate
      item={{
        name: '',
        phonenumber: '',
        executor: null,
        scheduleTime: null,
        comment: null,
      }}
      labelButtonCreate={label}
      config={{
        state,
        onChange: handleCreate,
        skipUnconfigured: true,
        props: {
          name: {
            editable: true,
            label: CallTaskPropName.name,
            required: true,
          },
          phonenumber: {
            editable: true,
            label: CallTaskPropName.phonenumber,
            required: true,
          },
          scheduleTime: {
            editable: true,
            label: CallTaskPropName.scheduleTime,
            type: 'date',
            required: true,
          },
          executor: {
            editable: true,
            label: CallTaskPropName.executor,
            render: ({ onChange, value }) => (
              <UserSelect onChange={onChange} user={value} />
            ),
            required: true,
          },
          comment: {
            editable: true,
            label: CallTaskPropName.comment,
            type: 'text',
          },
        },
      }}
    />
  );
};
