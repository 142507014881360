import React from 'react';

//shared
import { User as IUser } from 'src/shared/api';

interface Props {
  user: IUser;
}

export const UserPreview: React.FunctionComponent<Props> = ({ user }) => {
  return (
    <div>
      {[
        [user.lastName, user.firstName, user.fatherName]
          .filter(item => item)
          .join(' '),
        user.phoneNumber,
      ]
        .filter(item => item)
        .join(', ')}
    </div>
  );
};
