import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useRouter } from 'src/hooks/useRouter';
import { routesMap } from 'src/routes';
import { apiService } from 'src/services/apiService';
import useSWR, { mutate } from 'swr';
import { Contractors } from '../Contractors';
import { ContractorSelect } from '../ContractorSelect';
import { Contractor } from 'src/shared/api';

interface Props {}

export const ContractorsPage: React.FunctionComponent<Props> = ({}) => {
  const [selectedContractor, setContractor] = useState<null | Contractor>(null);

  const { query, ...router } = useRouter();

  const requestQuery = {
    limit: query.limit || 100,
    offset: query.offset || 0,
  };

  const swrKey = [
    apiService.v1Api.contractorsList.name,
    requestQuery.limit,
    requestQuery.offset,
  ];

  const { data, isValidating } = useSWR(
    swrKey,
    () =>
      apiService.v1Api
        .contractorsList(requestQuery)
        .then((res: any) => res.data),
    { revalidateOnFocus: false },
  );

  const push = (query: any) =>
    router.push(
      `${routesMap.contractors.getRoute()}?${new URLSearchParams(query)}`,
    );

  const handleOnChange = (params: { page: number; limit: number }) => {
    const nextRequestQuery = {
      ...requestQuery,
      offset: params.page * params.limit,
      limit: params.limit,
    };

    push(nextRequestQuery);
  };

  return (
    <div>
      <Box>
        <ContractorSelect onSelect={setContractor} />
      </Box>
      <Box mt={2}>
        <Paper>
          <Contractors
            contractors={selectedContractor ? [selectedContractor] : data?.data}
            totalCount={selectedContractor ? 1 : data?.totalCount}
            onFetch={() => mutate(swrKey)}
            onChange={handleOnChange}
            loading={isValidating}
            limit={parseInt(requestQuery.limit, 10)}
            offset={parseInt(requestQuery.offset, 10)}
          />
        </Paper>
      </Box>
    </div>
  );
};
