import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';

import { getPhonecallRecord } from '../api';

//shared
import { PhoneCall as IPhoneCall } from 'src/shared/api';

interface Props {
  phoneCall: IPhoneCall | null;
}

export const PhoneCall: React.FunctionComponent<Props> = ({ phoneCall }) => {
  const [record, setRecord] = useState<null | { data: string }>(null);

  const handleGetRecord = () =>
    phoneCall && getPhonecallRecord(phoneCall.callId).then(setRecord);

  if (!phoneCall) return null;

  return (
    <div>
      <Box>
        <Typography>Звонок</Typography>
      </Box>
      <Box mt={2}>
        {format(new Date(phoneCall.createdAt), 'yyyy-MM-dd HH:mm:ss')}{' '}
        {phoneCall.caller} {'>'} {phoneCall.receiver}
      </Box>
      <Box mt={1}>
        {!record && (
          <Button onClick={handleGetRecord} variant="outlined">
            Получить запись
          </Button>
        )}
        {record?.data && (
          <audio controls>
            <source src={record?.data} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </Box>
    </div>
  );
};
