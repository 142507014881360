import React, { useMemo } from 'react';
import { Box, Icon, Link, Paper, Tooltip, Typography } from '@mui/material';
import { Table } from 'src/components/common/Table';
import { format } from 'date-fns';
import { Order, OrderState, OrderWithMeta } from 'src/types/api';
import { routesMap } from 'src/routes';
import { CommentOutlined, InfoOutlined, Warning } from '@mui/icons-material';
import { declOfNum, formatPrice } from 'src/helpers/formatting';
import DownloadXlsx from 'src/components/common/DownloadXlsx';
import { green, orange } from '@mui/material/colors';

interface Props {
  loading: boolean;
  data?: OrderWithMeta[] | null;
  totalCount?: number | null;
  limit?: number | null;
  offset?: number | null;
  onFetch?: () => any;
  onClickRow?: (order: Order) => any;
  onChange?: (params: { page: number; limit: number }) => any;
}

export const Orders: React.FunctionComponent<Props> = ({
  data,
  totalCount,
  loading,
  limit = 50,
  offset = 0,
  onChange,
  onFetch,
  onClickRow,
}) => {
  const xlsxContent = useMemo(
    () =>
      data?.map(({ data: item }) => ({
        date: item.createdAt && format(new Date(item.createdAt), 'yyyy-MM-dd'),
        customerId: item.outlet?.contractor?.id,
        customer: item.outlet?.contractor?.name,
        region: item.outlet?.address?.line,
        address: item.outlet?.address?.line,
        month: item.createdAt && format(new Date(item.createdAt), 'yyyy-MM'),
        cost: String(item.totalCost && item.totalCost / 100),
      })),
    [data],
  );

  return (
    <div>
      <Paper>
        <Table
          title="Заказы"
          withPagination
          headers={[
            {
              title: 'id',
              component: 'th',
              scope: 'row',
              align: 'right',
              extract: (item: OrderWithMeta) =>
                item?.data?.id && (
                  <Link
                    href={routesMap.order.getRoute(item?.data?.id)}
                    target="_blank"
                  >
                    {item.data?.id}
                  </Link>
                ),
            },
            {
              title: 'Дата создания',
              extract: (item: OrderWithMeta) =>
                item.data?.updatedAt &&
                format(new Date(item.data?.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
            },
            {
              title: 'Пользователь',
              extract: (item: OrderWithMeta) => (
                <Box sx={theme => ({ fontSize: theme.typography.pxToRem(10) })}>
                  <div>
                    {item.data?.creator?.id && (
                      <Link
                        href={routesMap?.user.getRoute(item.data?.creator?.id)}
                      >
                        {item.data?.creator?.lastName}{' '}
                        {item.data?.creator?.firstName}{' '}
                        {item.data?.creator?.fatherName}
                      </Link>
                    )}
                  </div>
                  <div>
                    <Link href={`tel:+7${item.data?.creator?.phoneNumber}`}>
                      +7{item.data?.creator?.phoneNumber}
                    </Link>
                  </div>
                  <div>{item.data?.creator?.email}</div>
                </Box>
              ),
            },
            {
              title: 'Контрагент',
              extract: (item: OrderWithMeta) => (
                <>
                  {item?.data?.outlet?.contractor?.id && (
                    <Link
                      href={routesMap?.contractor.getRoute(
                        item?.data?.outlet?.contractor?.id,
                      )}
                    >
                      {item.data?.outlet?.contractor?.name}
                    </Link>
                  )}
                </>
              ),
            },
            {
              title: 'Торговая точка',
              extract: (item: OrderWithMeta) =>
                item.data?.outlet?.address?.line,
            },
            {
              title: 'Статус',
              extract: (item: OrderWithMeta) => (
                <Box display="flex" title={item.data.state}>
                  <Box
                    sx={{
                      backgroundColor: [
                        OrderState.Draft,
                        OrderState.PendingConfiramtion,
                      ].includes(item.data.state)
                        ? orange[800]
                        : 'unset',
                    }}
                  >
                    {item.data.state}
                  </Box>
                </Box>
              ),
            },
            {
              title: '',
              extract: (item: OrderWithMeta) => (
                <Box display="flex">
                  {(!item.zakazesCount ||
                    item.zakazesCount !== item.realizationsCount) && (
                    <Tooltip
                      title={
                        <Typography>
                          {item.zakazesCount === 0
                            ? 'Нет заказа(ов)'
                            : 'Нет реализации(й)'}
                        </Typography>
                      }
                      arrow
                    >
                      <Icon color="error">
                        <InfoOutlined />
                      </Icon>
                    </Tooltip>
                  )}
                  {Boolean(item.errorsCount) && (
                    <Tooltip
                      title={
                        <Typography>
                          {item.zakazesCount === 1 ? 'Ошибка' : 'Ошибки'}
                        </Typography>
                      }
                      arrow
                    >
                      <Icon color="error">
                        <Warning />
                      </Icon>
                    </Tooltip>
                  )}
                </Box>
              ),
            },
            {
              title: '',
              align: 'right',
              extract: (item: OrderWithMeta) =>
                item?.data?.comment && (
                  <Tooltip
                    title={<Typography>{item?.data?.comment}</Typography>}
                    arrow
                  >
                    <CommentOutlined sx={{ color: green[400] }} />
                  </Tooltip>
                ),
            },
            {
              title: 'Сумма',
              align: 'right',
              extract: (item: OrderWithMeta) =>
                `${item.data?.totalCost && item.data?.totalCost / 100}₽`,
            },
          ]}
          data={data}
          limit={limit}
          offset={offset}
          totalCount={totalCount}
          onFetch={onFetch}
          onChange={onChange}
          loading={loading}
          onClickRow={onClickRow}
        />
      </Paper>
      <Box mt={2}>
        <Paper>
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <div>
                {data?.length || 0}{' '}
                {declOfNum(['заказ', 'заказа', 'заказов'])(data?.length || 0)}
              </div>
              <DownloadXlsx
                title="Скачать список"
                disabled={!data}
                count={data?.length}
                json={[
                  {
                    sheet: 'Orders',
                    columns: [
                      { label: 'Дата', value: 'date' },
                      { label: 'Mecяц', value: 'month' },
                      { label: 'ID контрагента', value: 'customerId' },
                      { label: 'Контрагент', value: 'customer' },
                      { label: 'Регион', value: 'region' },
                      { label: 'Адрес', value: 'address' },
                      { label: 'Сумма', value: 'cost' },
                    ],
                    content: xlsxContent,
                  },
                ]}
              />
            </Box>
            <div>
              <b>
                {formatPrice(
                  (data?.reduce(
                    (prev: number, curr: OrderWithMeta) =>
                      prev +
                      ((curr?.data?.totalCost && curr?.data?.totalCost) || 0),
                    0,
                  ) || 0) / 100,
                )}
                {' ₽ '}
              </b>
            </div>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};
