import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import { useCreateUser } from 'src/services/apiService';
import { ContractorSelect } from './ContractorSelect';
import { WithLoading } from './common/WithLoading';

//shared
import { Contractor } from 'src/shared/api';

interface Props {
  onCreate?: () => any;
}

export const UserCreate: React.FunctionComponent<Props> = ({ onCreate }) => {
  const [dialog, setDialog] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [fatherName, setFatherName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contractor, setContractor] = useState<Contractor | null>(null);

  const create = useCreateUser();

  const isValid = phoneNumber && contractor && password;

  const handleCreate = () => {
    if (isValid && contractor) {
      const user = {
        email: email || null,
        phoneNumber,
        firstName: firstName || null,
        fatherName: fatherName || null,
        lastName: lastName || null,
        password,
        contractors: [contractor],
      };
      create
        .execute({
          user,
        })
        .then(() => {
          onCreate && onCreate();
          setEmail('');
          setPhoneNumber('');
          setContractor(null);
          setFirstName(null);
          setFatherName(null);
          setLastName(null);
          setPassword(null);
          setDialog(false);
        });
    }
  };

  return (
    <div>
      <IconButton
        color="primary"
        onClick={() => setDialog(true)}
        title="Создать пользователя"
      >
        <Add />
      </IconButton>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Создание пользователя</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Email"
              value={email || ''}
              onChange={e => setEmail(e.target.value)}
            />
          </Box>

          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Телефон"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Фамилия"
              value={lastName || ''}
              onChange={e => setLastName(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Имя"
              value={firstName || ''}
              onChange={e => setFirstName(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Отчество"
              value={fatherName || ''}
              onChange={e => setFatherName(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <ContractorSelect onSelect={setContractor} />
          </Box>
          {contractor?.outlets?.filter(outlet => outlet.enabled)?.length ===
            0 && (
            <Box mt={2}>
              <Alert severity="error">
                У контрагента отсутствуют торговые точки
              </Alert>
            </Box>
          )}
          <Box mt={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              label="Пароль"
              value={password || ''}
              onChange={e => setPassword(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <WithLoading loading={create.isLoading}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCreate}
              disabled={!isValid || create.isLoading}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
