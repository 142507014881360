import React from 'react';
import { TextField, CircularProgress, Autocomplete as MuiAutocomplete } from '@mui/material';


interface Props {
  options: any[];
  getOptionLabel?: (item: any) => any;
  onChangeTextField?: (item: any) => any;
  label?: string;
  loading?: boolean;
  clearOnBlur?: boolean;
  renderTags?: any;
  onChange?: (e: any, value: any) => any;
  value?: any;
  fullWidth?: boolean;
  actions?: React.ReactNode[];
  filterOptions?: any;
}

export const Autocomplete: React.FunctionComponent<Props> = ({
  options,
  getOptionLabel,
  onChangeTextField,
  label,
  loading,
  renderTags,
  value,
  onChange,
  fullWidth,
  clearOnBlur,
  actions = [],
  filterOptions,
}) => {
  return (
    <MuiAutocomplete
      // disabled={!body || disabled}
      fullWidth={fullWidth}
      value={value}
      filterOptions={filterOptions}
      clearOnBlur={clearOnBlur}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      renderTags={renderTags}
      loading={loading}
      noOptionsText="Ничего не найдено"
      loadingText="Загрузка..."
      renderInput={(params: any) => (
        <TextField
          {...params}
          onChange={onChangeTextField}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {actions}
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
