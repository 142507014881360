import React, { PropsWithChildren } from 'react';

import { AppProvider, Session } from '@toolpad/core/AppProvider';
import { DashboardLayout, ThemeSwitcher } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';

import { useRouter } from 'src/hooks/useRouter';
import {
  apiService,
  useGetOrders,
  useGetUserMe,
} from 'src/services/apiService';
import { useGetSusOrders } from 'src/entities/order';
import { routes, routesMap } from 'src/routes';
import { Warning } from '@mui/icons-material';
import { Box, createTheme, IconButton, Stack } from '@mui/material';

interface Props {}

// const useStyles = makeStyles(
//   theme =>
//     createStyles({
//       root: {
//         display: 'flex',
//       },
//       appBar: {
//         transition: theme.transitions.create(['margin', 'width'], {
//           easing: theme.transitions.easing.sharp,
//           duration: theme.transitions.duration.leavingScreen,
//         }),
//       },
//       appBarShift: {
//         width: `calc(100% - ${drawerWidth}px)`,
//         marginLeft: drawerWidth,
//         transition: theme.transitions.create(['margin', 'width'], {
//           easing: theme.transitions.easing.easeOut,
//           duration: theme.transitions.duration.enteringScreen,
//         }),
//       },
//       hide: {
//         display: 'none',
//       },
//       drawer: {
//         width: drawerWidth,
//         flexShrink: 0,
//       },
//       drawerPaper: {
//         width: drawerWidth,
//       },
//       drawerHeader: {
//         display: 'flex',
//         alignItems: 'center',
//         padding: theme.spacing(0, 1),
//         // necessary for content to be below app bar
//         ...theme.mixins.toolbar,
//         justifyContent: 'flex-end',
//       },
//       content: {
//         flexGrow: 1,
//         padding: theme.spacing(3),
//         // space for bottom navbar
//         paddingBottom: '60px',
//         transition: theme.transitions.create('margin', {
//           easing: theme.transitions.easing.sharp,
//           duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginLeft: -drawerWidth,
//       },
//       contentShift: {
//         transition: theme.transitions.create('margin', {
//           easing: theme.transitions.easing.easeOut,
//           duration: theme.transitions.duration.enteringScreen,
//         }),
//         marginLeft: 0,
//       },
//       toolbar: {
//         display: 'flex',
//         justifyContent: 'space-between',
//       },
//     }),
//   { name: 'MainLayout' },
// );

const BRANDING = {
  title: 'crm.instock-dv.ru',
  logo: <></>,
};

function ToolbarActionsSearch() {
  const { push } = useRouter();
  const susOrdersRequest = useGetSusOrders();

  const ordersRequest = useGetOrders({
    variables: { state: 'review' },
  });
  return (
    <Stack direction="row">
      <IconButton href="https://instock-dv.ru" target="_blank">
        <Box
          width={24}
          height={24}
          display="flex"
          alignItems="center"
          justifyItems="center"
        >
          <img
            src="/logo/logo_1.svg"
            style={{ width: '100%' }}
            alt="instock-dv.ru"
          />
        </Box>
      </IconButton>
      {Boolean(susOrdersRequest.data?.data?.length) && (
        <IconButton
          href="https://api.instock-dv.ru/v1_api_private/sus_orders"
          color="error"
        >
          <Box
            width={24}
            height={24}
            display="flex"
            alignItems="center"
            justifyItems="center"
          >
            <Warning />
          </Box>
        </IconButton>
      )}
      {Boolean(
        susOrdersRequest.data?.data?.length || ordersRequest.data?.data?.length,
      ) && (
        <IconButton
          color="warning"
          onClick={() => push(`${routesMap.orders.getRoute()}?state=review`)}
        >
          <Warning />
        </IconButton>
      )}
      <ThemeSwitcher />
    </Stack>
  );
}

const customTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: '#F9F9FE',
          paper: '#EEEEF9',
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: '#2A4364',
          paper: '#112E4D',
        },
        divider: 'rgba(255, 255, 255, 0.12)',
      },
    },
  },
});

export const MainLayout: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
}) => {
  //@ts-ignore
  const { push } = useRouter();

  const userRequest = useGetUserMe();

  const handleLogout = () =>
    apiService.v1Api.loginDelete().then(
      () => push('/login'),
      () => push('/login'),
    );

  const session =
    userRequest.data &&
    ({
      user: {
        id: userRequest.data.id,
        name: userRequest.data.lastName
          ? [
              userRequest.data.lastName,
              userRequest.data.firstName,
              userRequest.data.fatherName,
            ]
              .filter(item => item)
              ?.join(' ')
          : `+7${userRequest.data.phoneNumber}`,
        email: userRequest.data.email || `+7${userRequest.data.phoneNumber}`,
      },
    } as Session);

  const navigation = session
    ? routes
        .filter(route => route.title)
        .map(route => ({
          segment: route.path.replace('/', ''),
          title: route.title,
        }))
    : [];

  return (
    <AppProvider
      theme={customTheme}
      session={session}
      navigation={navigation}
      // router={router}
      // theme={demoTheme}
      // window={demoWindow}
      authentication={{
        signIn: () => push('/login'),
        signOut: handleLogout,
      }}
      branding={BRANDING}
    >
      <DashboardLayout
        slots={{
          toolbarActions: ToolbarActionsSearch,
        }}
      >
        <PageContainer maxWidth="xl">
          <main>{children}</main>
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );

  // return (
  //   <Box sx={{ display: 'flex' }}>
  //     <AppBar
  //       position="fixed"
  //       className={clsx(classes.appBar, {
  //         [classes.appBarShift]: open,
  //       })}
  //     >
  //       <Toolbar className={classes.toolbar}>
  //         <Box>
  //           <IconButton
  //             color="inherit"
  //             aria-label="open drawer"
  //             onClick={handleDrawerOpen}
  //             edge="start"
  //             classNamIconButton={e =>
  //               e.key === 'Enter' &&
  //               query &&
  //               typeof window !== 'undefined' &&
  //               window
  //                 ?.open(
  //                   `${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?query=${query}`,
  //                   '_blank',
  //                 )
  //                 ?.focus()
  //             }
  //             InputProps={{
  //               endAdornment: (
  //                 <InputAdornment position="end">
  //                   <IconButton
  //                     disabled={!query}
  //                     onClick={() =>
  //                       query &&
  //                       typeof window !== 'undefined' &&
  //                       window
  //                         ?.open(
  //                           `${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?query=${query}`,
  //                           '_blank',
  //                         )
  //                         ?.focus()
  //                     }
  //                   >
  //                     <Search />
  //                   </IconButton>
  //                 </InputAdornment>
  //               ),
  //             }}
  //           />
  //         </Box>
  //         {Boolean(ordersRequest.data?.data?.length) && (
  //           <Box px={2}>
  //             <IconButton
  //               href={`${routesMap.orders.getRoute()}?state=review`}
  //               color="warning"
  //               title="Требуется обратка заказов"
  //             >
  //               <Box
  //                 width={24}
  //                 height={24}
  //                 display="flex"
  //                 alignItems="center"
  //                 justifyItems="center"
  //               >
  //                 <Warning />
  //               </Box>
  //             </IconButton>
  //           </Box>
  //         )}
  //         {Boolean(susOrdersRequest.data?.data?.length) && (
  //           <Box px={2}>
  //             <IconButton
  //               href="https://api.instock-dv.ru/v1_api_private/sus_orders"
  //               color="error"
  //             >
  //               <Box
  //                 width={24}
  //                 height={24}
  //                 display="flex"
  //                 alignItems="center"
  //                 justifyItems="center"
  //               >
  //                 <Warning />
  //               </Box>
  //             </IconButton>
  //           </Box>
  //         )}
  //         <Box px={2}>
  //           <IconButton href="http://instock-dv.ru/">
  //             <Box
  //               width={24}
  //               height={24}
  //               display="flex"
  //               alignItems="center"
  //               justifyItems="center"
  //             >
  //               <img src="/logo/logo_1.svg" style={{ width: '100%' }} />
  //             </Box>
  //           </IconButton>
  //         </Box>
  //         <Box display="flex" alignItems="center">
  //           {userRequest?.data?.firstName ||
  //             userRequest?.data?.email ||
  //             userRequest?.data?.phoneNumber}
  //           <Box ml={2}>
  //             <IconButton
  //               color="inherit"
  //               aria-label="logout"
  //               title="Выйти"
  //               onClick={handleLogout}
  //             >
  //               <ExitToApp />
  //             </IconButton>
  //           </Box>
  //         </Box>
  //       </Toolbar>
  //     </AppBar>
  //     <Drawer
  //       className={classes.drawer}
  //       variant="persistent"
  //       anchor="left"
  //       open={open}
  //       classes={{
  //         paper: classes.drawerPaper,
  //       }}
  //     >
  //       <div className={classes.drawerHeader}>
  //         <IconButton onClick={handleDrawerClose}>
  //           <ChevronLeft />
  //         </IconButton>
  //       </div>
  //       <Divider />
  //       <List>
  //         {routes
  //           .filter(
  //             item =>
  //               item.menuPath &&
  //               checkIsPermitted(item.roles, userRequest.data?.roles),
  //           )
  //           .map(
  //             item =>
  //               item.menuPath && (
  //                 <ListItem component={Link} to={item.menuPath} key={item.path}>
  //                   <ListItemText primary={item.title} />
  //                 </ListItem>
  //               ),
  //           )}
  //       </List>
  //       <Divider />
  //       {/* <List>
  //         {['All mail', 'Trash', 'Spam'].map((text, index) => (
  //           <ListItem button key={text}>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <Inbox /> : <Mail />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItem>
  //         ))}
  //       </List> */}
  //     </Drawer>
  //     <main
  //       className={clsx(classes.content, {
  //         [classes.contentShift]: open,
  //       })}
  //     >
  //       <div className={classes.drawerHeader} />
  //       {children}
  //     </main>
  //   </Box>
  // );
};
