import React, { PropsWithChildren, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { StockItem } from 'src/types/api';
import { StockItemPrice } from './StockItemPrice';

interface Props {
  items?: StockItem[];
}

export const StockItemsDialog: React.FunctionComponent<
  PropsWithChildren<Props>
> = ({ items, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent>
          {items?.map(item => (
            <Grid container key={item?.id} spacing={2}>
              <Grid sx={{ xs: 8 }}>{item?.warehouse?.address?.line}</Grid>
              <Grid sx={{ xs: 2 }}>
                <Box display="flex" justifyContent="flex-end">
                  {item?.publicStock}
                </Box>
              </Grid>
              <Grid sx={{ xs: 2 }}>
                <Box display="flex" justifyContent="flex-end">
                  <StockItemPrice offerPrice={item?.offerPrice} />
                </Box>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>{' '}
      </Dialog>
      <Box
        component="button"
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          backgroundColor: 'unset',
          border: 'unset',
          color: 'currentColor',
        }}
      >
        {children}
      </Box>
    </>
  );
};
