import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

interface Props {
  id?: string | number | null;
  title?: string | number | null;
}

export const EntityTitle: React.FunctionComponent<Props> = ({ id, title }) => {
  return (
    <div>
      <Typography variant="h5">
        {id && (
          <Box sx={theme => ({ color: theme.palette.grey[500] })}>#{id}</Box>
        )}{' '}
        {title}
      </Typography>
    </div>
  );
};
