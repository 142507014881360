import React, { useState } from 'react';
import {
  Alert,
  BottomNavigationAction,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {
  apiService,
  useGetPromotion,
  useUpdatePromotion,
} from 'src/services/apiService';
import { useRouter } from 'src/hooks/useRouter';
import { EntityTitle } from 'src/components/EntityTitle';
import { Close, Link, Save } from '@mui/icons-material';
import { BottomNavigation } from '../BottomNavigation';
import { Promotion } from 'src/types/api';
import { FileUpload } from 'src/components/common/FileUpload';
import { DateControl } from 'src/shared/ui/entity-control';
import { format } from 'date-fns';

interface Props {}

export const PromotionPage: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const request = useGetPromotion({ promotionId: router.query.promotionId });

  const [state, changeState] = useState<Partial<Promotion>>({});
  const [errors, setErrors] = useState<string[]>([]);

  const setState = (data: Partial<Promotion>) => {
    if (errors.length) {
      setErrors([]);
    }
    changeState({ ...state, ...data });
  };

  const [update] = useUpdatePromotion();

  if (!request.data) return null;

  const handleUpdate = () => {
    let url;
    try {
      if (state.url) {
        url = new URL(state.url);
      }
    } catch {
      setErrors(errors => [...errors, 'Некорректный URL']);
    }

    if (state.url && url?.hostname !== 'instock-dv.ru') {
      setErrors(errors => [...errors, 'Некорректный домен URL']);
    }

    if (request.data?.id && !errors.length) {
      update({
        id: request.data.id,
        data: {
          ...state,
          start: state.start && format(new Date(state.start), 'yyyy-MM-dd'),
          finish: state.finish && format(new Date(state.finish), 'yyyy-MM-dd'),
        },
      }).then(request.refetch);
    }
  };

  const handleUploadImage = (files: File[], type: 'banner' | 'wobbler') => {
    if (!files[0] || !request.data?.id) return null;
    ({
      banner: apiService.v1ApiPrivate.promotionsUploadBanner,
      wobbler: apiService.v1ApiPrivate.promotionsUploadWobbler,
    })
      [type](request?.data?.id as string, { [type]: files[0] })
      .then(request.refetch);
  };

  const handleDeleteImage = (type: 'banner' | 'wobbler') => {
    if (!request.data?.id) return null;
    ({
      banner: apiService.v1ApiPrivate.promotionsBannerDelete,
      wobbler: apiService.v1ApiPrivate.promotionsWobblerDelete,
    })
      [type](request?.data?.id as string)
      .then(request.refetch);
  };

  return (
    <div>
      <EntityTitle id={request?.data?.id} title={request?.data?.title} />
      <Box mt={2}>
        <TextField
          label="vendoerId"
          variant="outlined"
          fullWidth
          disabled
          value={request?.data?.vendorId || ''}
        />
      </Box>
      <Box mt={2}>
        <TextField
          label="Оригинальное название"
          variant="outlined"
          fullWidth
          disabled
          value={request?.data?.vendorName || ''}
        />
      </Box>
      <Box mt={2}>
        <TextField
          label="Публичное название"
          variant="outlined"
          fullWidth
          value={(state?.title ?? request?.data?.title) || ''}
          onChange={e => setState({ title: e.target.value })}
        />
      </Box>
      <Box mt={2} maxWidth="220px">
        <DateControl
          editable
          label="Дата начала"
          value={(state?.start ?? request?.data?.start) || null}
          onChange={start => start && setState({ start })}
        />
      </Box>
      <Box mt={2} maxWidth="220px">
        <DateControl
          editable
          label="Дата окончания"
          value={(state?.finish ?? request?.data?.finish) || null}
          onChange={finish => finish && setState({ finish })}
        />
      </Box>
      <Box mt={2}>
        <TextField
          label="URL"
          variant="outlined"
          fullWidth
          value={(state?.url ?? request?.data?.url) || ''}
          onChange={e => setState({ url: e.target.value })}
          InputProps={{
            endAdornment: request?.data?.url && (
              <InputAdornment position="end">
                <IconButton href={request?.data?.url} target="_blank">
                  <Link />
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText="Пример: https://instock-dv.ru/brands/313"
        />
      </Box>
      {/* <Box mt={2}>
        <TextField
          label="Подпись"
          fullWidth
          variant="outlined"
          value={(state?.caption ?? request?.data?.caption) || ''}
          onChange={e => setState({ caption: e.target.value })}
        />
      </Box> */}
      <Box mt={2}>
        <TextField
          label="Описание"
          fullWidth
          variant="outlined"
          value={(state?.description ?? request?.data?.description) || ''}
          onChange={e => setState({ description: e.target.value })}
        />
      </Box>
      {/* <Box mt={2} display="flex" alignItems="center">
        <TextField
          label="Цвет фона"
          variant="outlined"
          value={
            (state?.backgroundColor ?? request?.data?.backgroundColor) || ''
          }
          onChange={e => setState({ backgroundColor: e.target.value })}
        />
        {Boolean(state?.backgroundColor ?? request?.data?.backgroundColor) && (
          <Box
            style={{
              borderRadius: '100%',
              width: '48px',
              height: '48px',
              background:
                (state?.backgroundColor ?? request?.data?.backgroundColor) ||
                'unset',
              marginLeft: '16px',
            }}
          />
        )}
      </Box> */}
      <Box mt={2} display="flex" alignItems="flex-end">
        <FileUpload
          lablel="Добавить банер"
          onChange={files => handleUploadImage(files, 'banner')}
        >
          {request?.data?.banner ? (
            <Box
              style={{
                padding: '0 24px',
                backgroundColor: request?.data?.backgroundColor || 'unset',
              }}
            >
              <img style={{ display: 'block' }} src={request?.data?.banner} />
            </Box>
          ) : null}
        </FileUpload>
        {Boolean(request?.data?.banner) && (
          <Box ml={2}>
            <Button
              variant="outlined"
              onClick={() => handleDeleteImage('banner')}
            >
              <Close />
              <Box ml={2}>Удалить банер</Box>
            </Button>
          </Box>
        )}
      </Box>
      {errors && (
        <Box>
          {errors.map(error => (
            <Box mt={1} key={error}>
              <Alert severity="error">{error}</Alert>
            </Box>
          ))}
        </Box>
      )}
      <BottomNavigation>
        <BottomNavigationAction
          label="Сохранить"
          icon={<Save />}
          onClick={handleUpdate}
        />
      </BottomNavigation>
    </div>
  );
};
