import React from 'react';
import { useParams } from 'react-router-dom';

//entities
import { User } from 'src/entities/user';
import { useGetUser } from 'src/entities/user';

interface Props {}

export const UserPage: React.FunctionComponent<Props> = ({}) => {
  const { userId } = useParams<{ userId: string }>();

  const userRequest = useGetUser({ variables: { id: userId as string } });

  return <User user={userRequest.data?.data} onChanage={userRequest.refetch} />;
};
