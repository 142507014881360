import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import useSWR, { mutate } from 'swr';

import { useRouter } from 'src/hooks/useRouter';
import { apiService } from 'src/services/apiService';

interface Props {
  redirect?: string;
}

export const LoginForm: React.FunctionComponent<Props> = ({ redirect }) => {
  const { push, location } = useRouter();

  const { mutate } = useSWR(apiService.v1Api.usersMeList.name, () =>
    apiService.v1Api.usersMeList().then(res => res.data),
  );

  const [state, setState] = useState({
    login: '',
    password: '',
  });
  const [error, setError] = useState<null | string>();

  const handleSignIn = () =>
    apiService.v1Api.loginCreate(state).then(
      res => {
        mutate(res.data);
        if (redirect === '/login' || location.pathname === '/login') {
          push('/');
        }
        redirect && push(redirect);
      },
      res => {
        setError(res?.error?.message);
      },
    );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '300px',
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          label="Логин"
          value={state.login}
          onChange={e => setState({ ...state, login: e.target.value })}
        />
        <Box mt={2}>
          <TextField
            variant="outlined"
            fullWidth
            label="Пароль"
            value={state.password}
            type="password"
            onChange={e => setState({ ...state, password: e.target.value })}
            onKeyPress={e => e.key === 'Enter' && handleSignIn()}
          />
        </Box>
        <Box mt={2}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSignIn}
          >
            Войти
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            minHeight: '50px',
          }}
        >
          {error}
        </Box>
      </Box>
    </Box>
  );
};
